.email-verification {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--grey-BBBBBB);
  margin: 0px;
  padding: 0px;
}
.email-verification .footer {
    margin-top: auto;
}