.message-banner {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  background: var(--grey-BBBBBB);
}
.message-banner img {
  max-width: 140px;
  max-height: 140px;
  align-self: center;
  object-fit: contain;
}
.message-banner div {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 50%;
  border-radius: 0.5rem;
  align-self: center;
}
.message-banner div h1 {
  align-self: center;
  font-weight: bolder;
  font-size: var(--font-size-normal);
}
.message-banner div p:nth-child(2) {
  font-weight: bolder;
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-top: 2rem;
}
.message-banner div p:nth-child(3) {
  font-size: var(--font-size-small);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}
.message-banner div button {
  width: fit-content;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 0.3rem;
}
.message-banner div small {
  color: var(--grey-BBBBBB);
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
@media (max-width: 450px) {
    .message-banner div {
        width: 100%;
    }
}