.approve-proposer {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
/* .message-banner img {
  width: 500px;
  max-height: 100px;
  align-self: center;
  object-fit: contain;
} */
.approve-proposer .message-banner div {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  min-height: 100px;
}
.approve-proposer .message-banner {
  flex: 1;
}
.approve-proposer .message-banner .table-resp {
  width: 100%;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  border-radius: 0px;
}
.approve-proposer .message-banner table {
  width: 100%;
}
.approve-proposer .message-banner table td:last-child {
  text-align: center;
}
.approve-proposer .message-banner table td {
  font-weight: 600;
  font-size: var(--font-size-small);
  padding: 0.75rem;
}
.approve-proposer .message-banner div p.greetings {
  font-weight: bolder;
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-top: 2rem;
  text-align: center;
}
.approve-proposer .message-banner div p.info {
  font-size: var(--font-size-semi-small);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
}
.approve-proposer .message-banner .actn-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.approve-proposer .message-banner div button {
  width: fit-content;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 0.3rem;
}
.footer {
  margin-top: auto;
}

@media (max-width: 930px) {
  .approve-proposer .message-banner div {
    width: 100%;
  }
}
