.alert {
  display: flex;

  font-size: var(--font-size-normal);
  font-weight: 600;
  background: rgb(255, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 0.15rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

}
.alert--danger {
  color:rgb(255, 0, 0, 0.8);
}
.alert--danger p {
  margin-left: 0.75rem;
}

