.jumbotron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: whitesmoke;
  padding: 3rem;
  margin: 5rem 0px;
}
.jumbotron p {
  font-size: var(--font-size-normal);
  font-weight: 600;
  color: var(--grey);
}
