.dialog-overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  cursor: pointer;
  visibility: hidden;
}
.dialog-overlay--visible {
  visibility: visible;
  height: 100%;
}
.dialog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #ffffff;
}
.dialog-body {
  padding: 20px;
  font-size: 0.91rem;
  font-weight: normal;
  color: var(--dark);
  line-height: 1.5rem;
  position: relative;
  z-index: 9999;
}
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
  background-color: white;
  width: 30vw;
  padding: 5px 30px;
  font-size: 0.9rem;
  position: relative;
  visibility: hidden;
  z-index: 5;
  margin-top: 150px;
}

.dialog-close-btn .times {
  font-weight: bold;
  height: 90%;
  width: 30px;
}
.dialog-content--visible {
  visibility: visible;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 20px;
}
.dialog-footer button {
  width: 40%;
  font-size: 0.7rem;
}
@media only screen and (min-width: 220px) and (max-width: 620px) {
  .dialog-content {
    width: 95vw;
  }
  .dialog-footer {
    width: 90%;
  }
}
@media only screen and (min-width: 620px) and (max-width: 1200px) {
  .dialog-content {
    width: 50vw;
  }
}
