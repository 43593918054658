.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #f8f8f8;
  padding: 3rem;
  margin: 5rem 0px;
  flex: 1;
  /* height: 100%; */
}
.loader h2 {
  margin-bottom: 0px;
  margin-bottom: 0rem;
  letter-spacing: 2px;
  font-family: 'futura-bold', sans-serif;
  letter-spacing: 0.45rem;
  font-weight: normal;
  /* animation: miliki-text 1.5s ease-in-out infinite; */
}
.loader p {
  font-size: var(--font-size-semi-small);
  font-weight: 600;
  margin-top: 0rem;
  opacity: 0.8;
  color: gray;
  font-family: 'futura-bold', sans-serif;
  letter-spacing: 0.25rem;
}
.loader img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.loader div {
  background: whitesmoke;
  position: absolute;
  display: flex;
  align-items: center;
  background: transparent;
  z-index: 99;
  height: 100%;
  width: 70px;
  animation: loader-shadow 1s infinite ease-in-out alternate;
}

@keyframes loader-shadow {
  0% {
    transform: translate3d(-70px, 0, 0);
  }
  50% {
    background: whitesmoke;
  }
  100% {
    transform: translate3d(90px, 0px, 0);
  }
}
@keyframes miliki-text {
  0% {
    opacity: 0;
  }
  /* 50% {
    opacity: 0.5;
  } */
  100% {
    opacity: 1;
  }
}