.no-network {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.no-network img {
  max-width: 90px;
  max-height: 90px;
  align-self: center;
}
.no-network .message-banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.no-network .message-banner .success-message h1 {
  margin-top: 3rem;
}
.no-network .message-banner p {
    color: var(--grey-BBBBBB);
}
.no-network .message-banner {
  flex: 1;
}

.footer {
  margin-top: auto;
}

@media (max-width: 450px) {
  .no-network .message-banner div {
    width: 100%;
  }
}
