.btn{
  width: inherit;
  /* box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15); */
  padding: 10px;
  border-radius: 3px;
  outline: none;
  font-weight: 800;
  color: var(--white);
}

.btn-outline-warning {
  color: var(--gold);
  background-color: var(--light-gold);
  background-image: none;
  border-color: var(--gold);
}

.btn-outline-danger {
  color: var(--red);
  background-color: var(--light-red);
  background-image: none;
  border-color: var(--red);
}

.btn-outline-success {
  color: var(--green);
  background-color: var(--light-green);
  background-image: none;
  border-color: var(--green);
}