.privacy-policy {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.privacy-policy ul {
  list-style: square;
  padding: 1rem 3rem 1rem 3rem;
}
/* .message-banner img {
    width: 500px;
    max-height: 100px;
    align-self: center;
    object-fit: contain;
  } */
.privacy-policy .message-banner div {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  min-height: 100px;
}
.privacy-policy .message-banner {
  flex: 1;
}
.policy-content {
  background-color: "red";
  display: flex;
  justify-content: center;
}
.privacy-policy .message-banner .table-resp {
  width: 100%;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  border-radius: 0px;
}
.privacy-policy .message-banner table {
  width: 100%;
}
.privacy-policy .message-banner table td:last-child {
  text-align: center;
}
.privacy-policy .message-banner table td {
  font-weight: 600;
  font-size: var(--font-size-small);
  padding: 0.75rem;
}
.privacy-policy .message-banner div p.greetings {
  font-weight: bolder;
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-top: 2rem;
}
.privacy-policy .message-banner div p.info {
  font-size: var(--font-size-semi-small);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
}
.privacy-policy .message-banner .actn-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy .message-banner div button {
  width: fit-content;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 0.3rem;
}
.footer {
  margin-top: auto;
}

@media (max-width: 930px) {
  .privacy-policy .message-banner div {
    width: 100%;
  }
}
