.input-text {
  padding: 5px;
  width: inherit;
  outline: none;
  border-radius: 3px;
  border: none;
  height: 40px;
  font-size: var(--font-size-semi-small);
  font-weight: 600;
  font-family: var(--open-sans);
  background: var(--white);
  color: #212121;
  /* z-index: -1; */
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: inherit;
  margin: 0px 0px;
  text-align: left;
  border: 1px solid var(--grey-BBBBBB);
  padding: 5px;
  height: fit-content;
  background: var(--white);
  border-radius: 0.5rem;
  /* z-index: -1; */
}
input::placeholder {
  color: var(--grey);
  font-size: 0.85rem;
}
.input-wrapper-label {
    color: var(--dark);
    font-weight: 600;
    padding-left: 5px;
    letter-spacing: 0;
    font-size: var(--font-size-semi-small);
    font-family: var(--open-sans);
  }
  
  .input-wrapper.red {
    border: 1px solid var(--red);
  }
  .input-wrapper.green {
    border: 1px solid var(--green);
  }
