@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn{
  width: inherit;
  /* box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15); */
  padding: 10px;
  border-radius: 3px;
  outline: none;
  font-weight: 800;
  color: var(--white);
}

.btn-outline-warning {
  color: var(--gold);
  background-color: var(--light-gold);
  background-image: none;
  border-color: var(--gold);
}

.btn-outline-danger {
  color: var(--red);
  background-color: var(--light-red);
  background-image: none;
  border-color: var(--red);
}

.btn-outline-success {
  color: var(--green);
  background-color: var(--light-green);
  background-image: none;
  border-color: var(--green);
}
.card {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 60px #00000008;
  border-radius: 5px;
  opacity: 1;
  padding: 15px;
  margin-bottom: 10px;
}


/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --page-header-bgColor: #242e42;
  --page-header-bgColor-hover: #1d2636;
  --page-header-txtColor: #dde9f8;
  --page-header-headingColor: #7889a4;
  --page-header-width: 220px;
  --page-content-bgColor: #f0f1f6;
  --page-content-txtColor: #404040;
  --page-content-blockColor: #fff;
  --white: #ffffff;
  --background-grey: #f5f5f5;
  --black: #404040;
  --blue: #00b0f0;
  --light-blue: #0070f0;
  --grey: #8d8585;
  --light-grey: #e6e6e6;
  --blue-links: #0070f0;
  --light-red: #ffe2e2;
  --red: #ff7b7b;
  --light-gold: #ffc10754;
  --gold: #fcaf30;
  --light-green: #c5fbca;
  --green: #0dbf6cfc;
  --border-radius: 4px;
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
  --sky-blue-f1f7f8: #f1f7f8;
  --dark: #212121;
  --dark-00000008: #00000008;
  --dark-303030: #303030;
  --dark-707070: #707070;
  --grey-808080: #808080;
  --grey-BBBBBB: #bbbbbb;
  --white-blue-ebt5f9: #ebf5f9;
  --white-smoke: whitesmoke;
  --orange: #ff8800;
  --open-sans: "open sans";
  --weight-normal: 20px/27px;
  --style-normal: normal;
  --input-height: 22px;
  --input-width: 300px;

  /*** SPACING ***/
  --font-size-large: 1.125rem;
  --font-size-normal: 1rem;
  --font-size-semi-small: 0.889rem;
  --font-size-small: 0.79rem;

  --base-margin: 1rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'futura-bold';
  src: url(/static/media/futura-bold.7bfa5614.ttf);
}
html {
  font-size: 100%/1.5 open sans sans-serif;
}
h1 {
  font-size: 1.802rem;
}

h2 {
  font-size: 1.602rem;
}
h3 {
  font-size: 1.424rem;
}
h4 {
  font-size: 1.266rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 1rem;
}

p {
  font-size: 0.875em;
}
ul {
  list-style: none;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  background: none;
  cursor: pointer;
}
.btn {
  background: #212121;
  background: var(--dark);
  border-radius: 5px;
}
.btn:hover {
  opacity: 0.8;
}

input {
  -webkit-appearance: none;
  display: inline;
}

button,
input {
  border: none;
}

svg {
  display: block;
}

.form-input-ctn {
  margin-bottom: 1rem;
}
.view-patron-card {
  margin-bottom: 20px;
}
.view-non-patron-card {
  margin-bottom: 20px;
}

.error-label {
  color: #ff7b7b;
  color: var(--red);
  font-weight: 600;
  padding-left: 5px;
  letter-spacing: 0;
  font-size: 0.75rem;
  font-family: "open sans";
  font-family: var(--open-sans);
}
.link {
  color: #0070f0;
  color: var(--blue-links);
  text-decoration: underline;
}
/* Tables  */
.responsive-table {
  overflow: auto;
}
table.details {
  width: 100%;
  border-spacing: 0 1.25rem;
}
table.details tr td {
  padding: 0.45rem 8px;

  padding-right: 3rem;
}
table.details td span {
  display: block;
  font-size: 0.889rem;
  font-size: var(--font-size-semi-small);
  font-weight: normal;
  /* line-height: 1.25rem; */
}
table.details td span:first-child {
  color: #8e8686;
  margin-bottom: 0.55rem;
}
@media (max-width: 600px){
  .form-input-ctn {
    margin-bottom: 0.45rem;
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #f8f8f8;
  padding: 3rem;
  margin: 5rem 0px;
  flex: 1 1;
  /* height: 100%; */
}
.loader h2 {
  margin-bottom: 0px;
  margin-bottom: 0rem;
  letter-spacing: 2px;
  font-family: 'futura-bold', sans-serif;
  letter-spacing: 0.45rem;
  font-weight: normal;
  /* animation: miliki-text 1.5s ease-in-out infinite; */
}
.loader p {
  font-size: var(--font-size-semi-small);
  font-weight: 600;
  margin-top: 0rem;
  opacity: 0.8;
  color: gray;
  font-family: 'futura-bold', sans-serif;
  letter-spacing: 0.25rem;
}
.loader img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.loader div {
  background: whitesmoke;
  position: absolute;
  display: flex;
  align-items: center;
  background: transparent;
  z-index: 99;
  height: 100%;
  width: 70px;
  animation: loader-shadow 1s infinite ease-in-out alternate;
}

@keyframes loader-shadow {
  0% {
    transform: translate3d(-70px, 0, 0);
  }
  50% {
    background: whitesmoke;
  }
  100% {
    transform: translate3d(90px, 0px, 0);
  }
}
@keyframes miliki-text {
  0% {
    opacity: 0;
  }
  /* 50% {
    opacity: 0.5;
  } */
  100% {
    opacity: 1;
  }
}
footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #000;
  padding: 2rem;
  color: var(--white);
  font-weight: 600;
  font-size: var(--font-size-small);
  align-items: center;
  height: 200px;
}
footer div.phone {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
}
footer p.email {
  text-align: center;
  margin-top: 0.75rem;
}
footer div>svg {
    color: var(--white);
    font-size: 0.7rem;
    /* margin-top: 0.18rem; */
}
footer a {
  color: var(--blue);
}
footer ul li {
    display: inline-block;
    margin-right: 00.75rem;
}
footer ul li svg {
    color: white
}
.accept-invite-success {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.accept-invite-success img {
  max-width: 90px;
  max-height: 90px;
  align-self: center;
}
.accept-invite-success .message-banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.accept-invite-success .message-banner .success-message h1 {
  margin-top: 3rem;
  text-align: center;
}
.accept-invite-success .message-banner .success-message span {
  margin-top: 1rem;
  font-weight: normal;
  font-size: var(--font-size-semi-small);
  text-align: center;
}
.accept-invite-success .message-banner {
  flex: 1 1;
}

.footer {
  margin-top: auto;
}

@media (max-width: 450px) {
  .accept-invite-success .message-banner div {
    width: 100%;
  }
}

.btn{
  cursor: pointer;
  padding: 1.3rem;
  border-radius: 0.55rem;
}
.btn-disabled {
  background: var(--grey);
}
.btn--outline {
  border: 1px solid var(--dark-707070);
  color: var(--dark);
  font-weight: 600;
  /* font-size: var(--font-size-normal); */
  background: none;
}
.btn--grey {
  border: 1px solid #EFF0F1;
  color: var(--dark);
  font-weight: 600;
  font-size: 0.7rem;
  background: #EFF0F1
}
.btn--text {
  background: var(--white);
  color: var(--dark);
  background-color:transparent;
}
.btn--outline:hover {
  background: var(--dark);
  color: var(--white);
}
.jumbotron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: whitesmoke;
  padding: 3rem;
  margin: 5rem 0px;
}
.jumbotron p {
  font-size: var(--font-size-normal);
  font-weight: 600;
  color: var(--grey);
}

.modal-overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  cursor: pointer;
  visibility: hidden;
}
.modal-overlay--visible {
  visibility: visible;
  height: 100%;
}

.modal-body {
  padding: 20px;
}
.modal-content {
  margin: 5% auto;
  background-color: white;
  max-width: 35vw;
  padding: 5px 0px;
  font-size: 0.9rem;
  position: relative;
  visibility: hidden;
  z-index: 5;
  margin-top: 150px;
}

.modal-content--visible {
  visibility: visible;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  padding: 0.5rem 20px;
}
.closeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--dark);
}
.closeWrap p {
  color: var(--white);
  font-size: var(--font-size-semi-small);

}
@media only screen and (min-width: 220px) and (max-width: 576px) {
  .modal-content {
    max-width: 90vw;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1030px) {
  .modal-content {
    max-width: 70vw;
  }
}

.approve-proposer {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
/* .message-banner img {
  width: 500px;
  max-height: 100px;
  align-self: center;
  object-fit: contain;
} */
.approve-proposer .message-banner div {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  min-height: 100px;
}
.approve-proposer .message-banner {
  flex: 1 1;
}
.approve-proposer .message-banner .table-resp {
  width: 100%;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  border-radius: 0px;
}
.approve-proposer .message-banner table {
  width: 100%;
}
.approve-proposer .message-banner table td:last-child {
  text-align: center;
}
.approve-proposer .message-banner table td {
  font-weight: 600;
  font-size: var(--font-size-small);
  padding: 0.75rem;
}
.approve-proposer .message-banner div p.greetings {
  font-weight: bolder;
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-top: 2rem;
  text-align: center;
}
.approve-proposer .message-banner div p.info {
  font-size: var(--font-size-semi-small);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
}
.approve-proposer .message-banner .actn-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.approve-proposer .message-banner div button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 0.3rem;
}
.footer {
  margin-top: auto;
}

@media (max-width: 930px) {
  .approve-proposer .message-banner div {
    width: 100%;
  }
}

.alert {
  display: flex;

  font-size: var(--font-size-normal);
  font-weight: 600;
  background: rgb(255, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 0.15rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

}
.alert--danger {
  color:rgb(255, 0, 0, 0.8);
}
.alert--danger p {
  margin-left: 0.75rem;
}


.input-text {
  padding: 5px;
  width: inherit;
  outline: none;
  border-radius: 3px;
  border: none;
  height: 40px;
  font-size: var(--font-size-semi-small);
  font-weight: 600;
  font-family: var(--open-sans);
  background: var(--white);
  color: #212121;
  /* z-index: -1; */
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: inherit;
  margin: 0px 0px;
  text-align: left;
  border: 1px solid var(--grey-BBBBBB);
  padding: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--white);
  border-radius: 0.5rem;
  /* z-index: -1; */
}
input::-webkit-input-placeholder {
  color: var(--grey);
  font-size: 0.85rem;
}
input:-ms-input-placeholder {
  color: var(--grey);
  font-size: 0.85rem;
}
input::placeholder {
  color: var(--grey);
  font-size: 0.85rem;
}
.input-wrapper-label {
    color: var(--dark);
    font-weight: 600;
    padding-left: 5px;
    letter-spacing: 0;
    font-size: var(--font-size-semi-small);
    font-family: var(--open-sans);
  }
  
  .input-wrapper.red {
    border: 1px solid var(--red);
  }
  .input-wrapper.green {
    border: 1px solid var(--green);
  }

.pass-code-form {
  display: flex;
  flex-direction: column;
}
.pass-code-form button {
  align-self: center;
  margin-top: 2rem;
  width: 50%;
}

.decline-invite-success {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.decline-invite-success img {
  max-width: 90px;
  max-height: 90px;
  align-self: center;
}
.decline-invite-success .message-banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.decline-invite-success .message-banner .success-message h1 {
  margin-top: 3rem;
  text-align: center;
}

.decline-invite-success .message-banner {
  flex: 1 1;
}

.footer {
  margin-top: auto;
}

@media (max-width: 450px) {
  .decline-invite-success .message-banner div {
    width: 100%;
  }
}

.message-banner {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  background: var(--grey-BBBBBB);
}
.message-banner img {
  max-width: 140px;
  max-height: 140px;
  align-self: center;
  object-fit: contain;
}
.message-banner div {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 50%;
  border-radius: 0.5rem;
  align-self: center;
}
.message-banner div h1 {
  align-self: center;
  font-weight: bolder;
  font-size: var(--font-size-normal);
}
.message-banner div p:nth-child(2) {
  font-weight: bolder;
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-top: 2rem;
}
.message-banner div p:nth-child(3) {
  font-size: var(--font-size-small);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}
.message-banner div button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 0.3rem;
}
.message-banner div small {
  color: var(--grey-BBBBBB);
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
@media (max-width: 450px) {
    .message-banner div {
        width: 100%;
    }
}
.email-verification {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--grey-BBBBBB);
  margin: 0px;
  padding: 0px;
}
.email-verification .footer {
    margin-top: auto;
}
.forgot-p-ctn {
  background: white;
  display: flex;
}
.forgot-p-ctn .left-ctn,
.forgot-p-ctn .right-ctn {
  background: whitesmoke;
  width: 50%;
  height: 100vh;
}
.forgot-p-ctn .left-ctn {
  border-bottom-right-radius: 70%;
  border-top-right-radius: 70%;
}
.forgot-p-ctn .right-ctn {
  border-bottom-left-radius: 70%;
  border-top-left-radius: 70%;
}
.forgot-passw-success .form-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}

.forgot-p-ctn .back-link {
  display: flex;
  flex-direction: column;
  text-align: center;
  font: var(--style-normal) bold var(--weight-normal) 12px var(--open-sans);
  letter-spacing: 0px;
  opacity: 1;
  font-size: 10px;
  font-weight: bold;
  margin-top: 100px;
  cursor: pointer;
  margin-bottom: 2%;
}
.back-link .back-arrow {
  margin-bottom: 7px;
}
.fa-long-arrow-left {
  font-size: 1.5rem;
}

.sm-text {
  text-align: center;
  margin-bottom: 25px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 25px;
}
.form-ctn .logo-ctn {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.form-ctn .logo-wrap {
  /* width: 65px;
  height: 65px; */
  background: transparent;
  border-radius: 50%;
  text-align: center;
}

.form-ctn .logo {
  max-width: 100px;
  max-height: 100px;
  position: relative;
  top: 20px;
}
.sub-title {
  font-size: var(--font-size-small);
  margin-top: 0.9rem;
  text-align: center;
}
.btn-email {
  width: 100%;
  background-color: var(--dark);
  color: var(--white);
  border-radius: 5px;
  font-weight: 600;
  margin-top: 20px;
  padding: 20px;
  font-size: 1.3rem;
}

/* mobile phones */
@media only screen and (min-width: 150px) and (max-width: 480px) {
  .main-container {
    flex-direction: column;
    padding: 3%;
  }
}

.guest-pass {
    display: flex;
    flex-direction: column;
    background: var(--grey-BBBBBB) ;
    height: 100vh;
    padding-top: 3rem;
  }
  .guest-pass img {
    max-width: 90px;
    max-height: 90px;
    align-self: center;
  }
  /* .guest-pass .message-banne div {
    display: flex;
    flex-direction: column;
    background: var(--white);
    padding: 1rem;
    margin-top: 1rem;
    width: 60%;
    align-self: center;
    min-height: 100px;
  } */
  .guest-pass .message-banner {
    flex: 1 1;
  }
  .guest-pass .message-banner .table-resp {
    width: 100%;
    border-top: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    border-radius: 0px;
  }
  .guest-pass .message-banner table {
    width: 100%;
  }
  .guest-pass .message-banner table td:last-child {
    text-align: center;
  }
  .guest-pass .message-banner table td {
    font-weight: 600;
    font-size: var(--font-size-small);
    padding: 0.75rem;
  }
  .guest-pass .message-banner div p.greetings {
    font-weight: bolder;
    font-size: var(--font-size-large);
    font-weight: 600;
    margin-top: 2rem;
  }
  .guest-pass .message-banner div p.info {
    font-size: var(--font-size-semi-small);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-weight: normal;
  }
  .guest-pass .qr-code {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .footer {
    margin-top: auto;
  }
  
  @media (max-width: 950px) {
    .guest-pass .message-banner div {
      width: 100%;
    }
  }
  
.password-reset {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--grey-BBBBBB);
  margin: 0px;
  padding: 0px;
}
.password-reset .footer {
  margin-top: auto;
}

.password-reset-success {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: var(--grey-BBBBBB);
    margin: 0px;
    padding: 0px;
  }
  .password-reset-success .footer {
    margin-top: auto;
  }
  
.privacy-policy {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.privacy-policy ul {
  list-style: square;
  padding: 1rem 3rem 1rem 3rem;
}
/* .message-banner img {
    width: 500px;
    max-height: 100px;
    align-self: center;
    object-fit: contain;
  } */
.privacy-policy .message-banner div {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  min-height: 100px;
}
.privacy-policy .message-banner {
  flex: 1 1;
}
.policy-content {
  background-color: "red";
  display: flex;
  justify-content: center;
}
.privacy-policy .message-banner .table-resp {
  width: 100%;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  border-radius: 0px;
}
.privacy-policy .message-banner table {
  width: 100%;
}
.privacy-policy .message-banner table td:last-child {
  text-align: center;
}
.privacy-policy .message-banner table td {
  font-weight: 600;
  font-size: var(--font-size-small);
  padding: 0.75rem;
}
.privacy-policy .message-banner div p.greetings {
  font-weight: bolder;
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-top: 2rem;
}
.privacy-policy .message-banner div p.info {
  font-size: var(--font-size-semi-small);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: normal;
}
.privacy-policy .message-banner .actn-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy .message-banner div button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem;
  margin-top: 0.7rem;
  border-radius: 0.3rem;
}
.footer {
  margin-top: auto;
}

@media (max-width: 930px) {
  .privacy-policy .message-banner div {
    width: 100%;
  }
}

.no-network {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.no-network img {
  max-width: 90px;
  max-height: 90px;
  align-self: center;
}
.no-network .message-banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.no-network .message-banner .success-message h1 {
  margin-top: 3rem;
}
.no-network .message-banner p {
    color: var(--grey-BBBBBB);
}
.no-network .message-banner {
  flex: 1 1;
}

.footer {
  margin-top: auto;
}

@media (max-width: 450px) {
  .no-network .message-banner div {
    width: 100%;
  }
}

.dialog-overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  cursor: pointer;
  visibility: hidden;
}
.dialog-overlay--visible {
  visibility: visible;
  height: 100%;
}
.dialog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #ffffff;
}
.dialog-body {
  padding: 20px;
  font-size: 0.91rem;
  font-weight: normal;
  color: var(--dark);
  line-height: 1.5rem;
  position: relative;
  z-index: 9999;
}
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
  background-color: white;
  width: 30vw;
  padding: 5px 30px;
  font-size: 0.9rem;
  position: relative;
  visibility: hidden;
  z-index: 5;
  margin-top: 150px;
}

.dialog-close-btn .times {
  font-weight: bold;
  height: 90%;
  width: 30px;
}
.dialog-content--visible {
  visibility: visible;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 20px;
}
.dialog-footer button {
  width: 40%;
  font-size: 0.7rem;
}
@media only screen and (min-width: 220px) and (max-width: 620px) {
  .dialog-content {
    width: 95vw;
  }
  .dialog-footer {
    width: 90%;
  }
}
@media only screen and (min-width: 620px) and (max-width: 1200px) {
  .dialog-content {
    width: 50vw;
  }
}

