.password-reset-success {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: var(--grey-BBBBBB);
    margin: 0px;
    padding: 0px;
  }
  .password-reset-success .footer {
    margin-top: auto;
  }
  