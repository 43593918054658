footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #000;
  padding: 2rem;
  color: var(--white);
  font-weight: 600;
  font-size: var(--font-size-small);
  align-items: center;
  height: 200px;
}
footer div.phone {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
}
footer p.email {
  text-align: center;
  margin-top: 0.75rem;
}
footer div>svg {
    color: var(--white);
    font-size: 0.7rem;
    /* margin-top: 0.18rem; */
}
footer a {
  color: var(--blue);
}
footer ul li {
    display: inline-block;
    margin-right: 00.75rem;
}
footer ul li svg {
    color: white
}