/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("./globalDefaultStyle/button.css");
@import url("./globalDefaultStyle/card.css");
:root {
  --page-header-bgColor: #242e42;
  --page-header-bgColor-hover: #1d2636;
  --page-header-txtColor: #dde9f8;
  --page-header-headingColor: #7889a4;
  --page-header-width: 220px;
  --page-content-bgColor: #f0f1f6;
  --page-content-txtColor: #404040;
  --page-content-blockColor: #fff;
  --white: #ffffff;
  --background-grey: #f5f5f5;
  --black: #404040;
  --blue: #00b0f0;
  --light-blue: #0070f0;
  --grey: #8d8585;
  --light-grey: #e6e6e6;
  --blue-links: #0070f0;
  --light-red: #ffe2e2;
  --red: #ff7b7b;
  --light-gold: #ffc10754;
  --gold: #fcaf30;
  --light-green: #c5fbca;
  --green: #0dbf6cfc;
  --border-radius: 4px;
  --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
  --sky-blue-f1f7f8: #f1f7f8;
  --dark: #212121;
  --dark-00000008: #00000008;
  --dark-303030: #303030;
  --dark-707070: #707070;
  --grey-808080: #808080;
  --grey-BBBBBB: #bbbbbb;
  --white-blue-ebt5f9: #ebf5f9;
  --white-smoke: whitesmoke;
  --orange: #ff8800;
  --open-sans: "open sans";
  --weight-normal: 20px/27px;
  --style-normal: normal;
  --input-height: 22px;
  --input-width: 300px;

  /*** SPACING ***/
  --font-size-large: 1.125rem;
  --font-size-normal: 1rem;
  --font-size-semi-small: 0.889rem;
  --font-size-small: 0.79rem;

  --base-margin: 1rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'futura-bold';
  src: url('./assets/fonts/futura-bold.ttf');
}
html {
  font-size: 100%/1.5 open sans sans-serif;
}
h1 {
  font-size: 1.802rem;
}

h2 {
  font-size: 1.602rem;
}
h3 {
  font-size: 1.424rem;
}
h4 {
  font-size: 1.266rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 1rem;
}

p {
  font-size: 0.875em;
}
ul {
  list-style: none;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  background: none;
  cursor: pointer;
}
.btn {
  background: var(--dark);
  border-radius: 5px;
}
.btn:hover {
  opacity: 0.8;
}

input {
  -webkit-appearance: none;
  display: inline;
}

button,
input {
  border: none;
}

svg {
  display: block;
}

.form-input-ctn {
  margin-bottom: 1rem;
}
.view-patron-card {
  margin-bottom: 20px;
}
.view-non-patron-card {
  margin-bottom: 20px;
}

.error-label {
  color: var(--red);
  font-weight: 600;
  padding-left: 5px;
  letter-spacing: 0;
  font-size: 0.75rem;
  font-family: var(--open-sans);
}
.link {
  color: var(--blue-links);
  text-decoration: underline;
}
/* Tables  */
.responsive-table {
  overflow: auto;
}
table.details {
  width: 100%;
  border-spacing: 0 1.25rem;
}
table.details tr td {
  padding: 0.45rem 8px;

  padding-right: 3rem;
}
table.details td span {
  display: block;
  font-size: var(--font-size-semi-small);
  font-weight: normal;
  /* line-height: 1.25rem; */
}
table.details td span:first-child {
  color: #8e8686;
  margin-bottom: 0.55rem;
}
@media (max-width: 600px){
  .form-input-ctn {
    margin-bottom: 0.45rem;
  }
}