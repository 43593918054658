.guest-pass {
    display: flex;
    flex-direction: column;
    background: var(--grey-BBBBBB) ;
    height: 100vh;
    padding-top: 3rem;
  }
  .guest-pass img {
    max-width: 90px;
    max-height: 90px;
    align-self: center;
  }
  /* .guest-pass .message-banne div {
    display: flex;
    flex-direction: column;
    background: var(--white);
    padding: 1rem;
    margin-top: 1rem;
    width: 60%;
    align-self: center;
    min-height: 100px;
  } */
  .guest-pass .message-banner {
    flex: 1;
  }
  .guest-pass .message-banner .table-resp {
    width: 100%;
    border-top: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    border-radius: 0px;
  }
  .guest-pass .message-banner table {
    width: 100%;
  }
  .guest-pass .message-banner table td:last-child {
    text-align: center;
  }
  .guest-pass .message-banner table td {
    font-weight: 600;
    font-size: var(--font-size-small);
    padding: 0.75rem;
  }
  .guest-pass .message-banner div p.greetings {
    font-weight: bolder;
    font-size: var(--font-size-large);
    font-weight: 600;
    margin-top: 2rem;
  }
  .guest-pass .message-banner div p.info {
    font-size: var(--font-size-semi-small);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-weight: normal;
  }
  .guest-pass .qr-code {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .footer {
    margin-top: auto;
  }
  
  @media (max-width: 950px) {
    .guest-pass .message-banner div {
      width: 100%;
    }
  }
  