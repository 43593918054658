.password-reset {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--grey-BBBBBB);
  margin: 0px;
  padding: 0px;
}
.password-reset .footer {
  margin-top: auto;
}
