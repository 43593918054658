.forgot-p-ctn {
  background: white;
  display: flex;
}
.forgot-p-ctn .left-ctn,
.forgot-p-ctn .right-ctn {
  background: whitesmoke;
  width: 50%;
  height: 100vh;
}
.forgot-p-ctn .left-ctn {
  border-bottom-right-radius: 70%;
  border-top-right-radius: 70%;
}
.forgot-p-ctn .right-ctn {
  border-bottom-left-radius: 70%;
  border-top-left-radius: 70%;
}
.forgot-passw-success .form-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}

.forgot-p-ctn .back-link {
  display: flex;
  flex-direction: column;
  text-align: center;
  font: var(--style-normal) bold var(--weight-normal) 12px var(--open-sans);
  letter-spacing: 0px;
  opacity: 1;
  font-size: 10px;
  font-weight: bold;
  margin-top: 100px;
  cursor: pointer;
  margin-bottom: 2%;
}
.back-link .back-arrow {
  margin-bottom: 7px;
}
.fa-long-arrow-left {
  font-size: 1.5rem;
}

.sm-text {
  text-align: center;
  margin-bottom: 25px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 25px;
}
.form-ctn .logo-ctn {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.form-ctn .logo-wrap {
  /* width: 65px;
  height: 65px; */
  background: transparent;
  border-radius: 50%;
  text-align: center;
}

.form-ctn .logo {
  max-width: 100px;
  max-height: 100px;
  position: relative;
  top: 20px;
}
.sub-title {
  font-size: var(--font-size-small);
  margin-top: 0.9rem;
  text-align: center;
}
.btn-email {
  width: 100%;
  background-color: var(--dark);
  color: var(--white);
  border-radius: 5px;
  font-weight: 600;
  margin-top: 20px;
  padding: 20px;
  font-size: 1.3rem;
}

/* mobile phones */
@media only screen and (min-width: 150px) and (max-width: 480px) {
  .main-container {
    flex-direction: column;
    padding: 3%;
  }
}
