.accept-invite-success {
  display: flex;
  flex-direction: column;
  background: var(--grey-BBBBBB);
  height: 100vh;
  padding-top: 3rem;
}
.accept-invite-success img {
  max-width: 90px;
  max-height: 90px;
  align-self: center;
}
.accept-invite-success .message-banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  padding: 1rem;
  margin-top: 1rem;
  width: 60%;
  align-self: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.accept-invite-success .message-banner .success-message h1 {
  margin-top: 3rem;
  text-align: center;
}
.accept-invite-success .message-banner .success-message span {
  margin-top: 1rem;
  font-weight: normal;
  font-size: var(--font-size-semi-small);
  text-align: center;
}
.accept-invite-success .message-banner {
  flex: 1;
}

.footer {
  margin-top: auto;
}

@media (max-width: 450px) {
  .accept-invite-success .message-banner div {
    width: 100%;
  }
}
