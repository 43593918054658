.btn{
  cursor: pointer;
  padding: 1.3rem;
  border-radius: 0.55rem;
}
.btn-disabled {
  background: var(--grey);
}
.btn--outline {
  border: 1px solid var(--dark-707070);
  color: var(--dark);
  font-weight: 600;
  /* font-size: var(--font-size-normal); */
  background: none;
}
.btn--grey {
  border: 1px solid #EFF0F1;
  color: var(--dark);
  font-weight: 600;
  font-size: 0.7rem;
  background: #EFF0F1
}
.btn--text {
  background: var(--white);
  color: var(--dark);
  background-color:transparent;
}
.btn--outline:hover {
  background: var(--dark);
  color: var(--white);
}