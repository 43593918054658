.modal-overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  cursor: pointer;
  visibility: hidden;
}
.modal-overlay--visible {
  visibility: visible;
  height: 100%;
}

.modal-body {
  padding: 20px;
}
.modal-content {
  margin: 5% auto;
  background-color: white;
  max-width: 35vw;
  padding: 5px 0px;
  font-size: 0.9rem;
  position: relative;
  visibility: hidden;
  z-index: 5;
  margin-top: 150px;
}

.modal-content--visible {
  visibility: visible;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  padding: 0.5rem 20px;
}
.closeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--dark);
}
.closeWrap p {
  color: var(--white);
  font-size: var(--font-size-semi-small);

}
@media only screen and (min-width: 220px) and (max-width: 576px) {
  .modal-content {
    max-width: 90vw;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1030px) {
  .modal-content {
    max-width: 70vw;
  }
}
